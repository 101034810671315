import React from "react";
import { Link } from "react-router-dom"; // Assuming you're using react-router-dom
import LogoBVDesktop from "../../assets/logoBV green 2.png";
import LogoBVMobile from "../../assets/logoBV green 2.png";
// Assuming LogoBVMobile and LogoBVDesktop are imported somewhere else

function SocialMedia() {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full">
        <div className="flex justify-center items-center">
          <Link to="/">
            <img src={LogoBVMobile} alt="Mobile Logo" className="md:hidden" />
          </Link>
          <Link to="/">
            <img
              src={LogoBVDesktop}
              alt="Desktop Logo"
              className="hidden md:block"
            />
          </Link>
        </div>
      </div>
      <div className="text-left mt-8 max-w-3xl mx-auto">
        {/* Centered content */}
        <h1 className="text-3xl font-semibold mb-4 text-center">
          Supply Chain Business Analyst
        </h1>
        <h2 className="text-xl font-medium mb-4 text-center">Job Type</h2>
        <p className="text-gray-700 mb-4 text-center">Full Time (Onsite Job)</p>
        <h2 className="text-xl font-medium mb-4 text-center">About Us</h2>
        <p className="text-gray-700 mb-4">
          At IT Solutions Hub 2010, we pride ourselves on being a trusted
          partner for businesses seeking strategic guidance and innovative
          solutions. With a wealth of experience across various industries, our
          team of seasoned consultants brings unparalleled expertise and a
          client-centric approach to every project. We are dedicated to
          delivering tailored strategies that drive operational excellence and
          sustainable growth. By joining our team, you will become part of a
          dynamic organization that values professional development, fosters a
          collaborative environment, and is committed to making a meaningful
          impact on our clients' success.
        </p>
        <h2 className="text-xl font-medium mb-4 text-center">
          Responsibilities
        </h2>
        <ul className="text-gray-700 mb-4 list-disc pl-5">
          <li>Manage timeline, scope and customer expectations.</li>
          <li>Execute multiple SCM projects at the same time.</li>
          <li>
            Manage customer requirements, resolve issues and escalate issues
            when needed.
          </li>
          <li>Definition of functional and technical SCM specifications.</li>
          <li>
            ERP configurations and testing (Oracle Fusion, SAP, and other
            applications).
          </li>
          <li>Provide user trainings.</li>
          <li>
            Develop relationships with customers in order to become a trusted
            Supply Chain advisor.
          </li>
          <li>Support end-to-end implementations.</li>
          <li>
            Gather functional requirements and convert these to technical
            requirements.
          </li>
        </ul>
        <h2 className="text-xl font-medium mb-4 text-center">Requirements</h2>
        <ul className="text-gray-700 mb-4 list-disc pl-5">
          <li>
            A Bachelor’s or Master’s degree in a related field or equivalent
            work experience.
          </li>
          <li>
            Extensive knowledge of Supply Chain processes including Procurement,
            Inventory Management, Manufacturing, and Maintenance.
          </li>
          <li>
            Minimum 3+ years consulting experience in implementing Oracle Fusion
            or 5+ years consulting experience in implementing equivalent ERP
            solutions.
          </li>
          <li>Excellent verbal and written English communication skills.</li>
          <li>Excellent presentation skills.</li>
          <li>
            Ability to adapt to different environments, industries, and business
            challenges.
          </li>
          <li>Adapt at building trust and relationships quickly.</li>
          <li>Creative, motivated, and growth-oriented team player.</li>
          <li>Word, Excel, PowerPoint skills.</li>
          <li>Able to create analyses in Excel or Power BI.</li>
          <li>Experience with creating solution design documents.</li>
        </ul>
      </div>
    </div>
  );
}

export default SocialMedia;
