import React from "react";
import { Link } from "react-router-dom"; // Assuming you're using react-router-dom
import LogoBVDesktop from "../../assets/logoBV green 2.png";
import LogoBVMobile from "../../assets/logoBV green 2.png";
// Assuming LogoBVMobile and LogoBVDesktop are imported somewhere else

function AndroidDeveloper() {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full">
        <div className="flex justify-center items-center">
          <Link to="/">
            <img src={LogoBVMobile} alt="Mobile Logo" className="md:hidden" />
          </Link>
          <Link to="/">
            <img
              src={LogoBVDesktop}
              alt="Desktop Logo"
              className="hidden md:block"
            />
          </Link>
        </div>
      </div>
      <div className="text-left mt-8 max-w-3xl mx-auto">
        {/* Centered content */}
        <h1 className="text-3xl font-semibold mb-4 text-center">
          Android Developer
        </h1>
        <h2 className="text-xl font-medium mb-4 text-center">
          Job Type: Full Time (Onsite)
        </h2>
        <h2 className="text-xl font-medium mb-4 text-center">About Us</h2>
        <p className="text-gray-700 mb-4">
          At IT Solutions Hub, we pride ourselves on being a trusted partner for
          businesses seeking strategic guidance and innovative solutions. With a
          wealth of experience across various industries, our team of seasoned
          consultants brings unparalleled expertise and a client-centric
          approach to every project. We are dedicated to delivering tailored
          strategies that drive operational excellence and sustainable growth.
          By joining our team, you will become part of a dynamic organization
          that values professional development, fosters a collaborative
          environment, and is committed to making a meaningful impact on our
          clients' success.
        </p>
        <h2 className="text-xl font-medium mb-4 text-center">
          Responsibilities
        </h2>
        <ul className="text-gray-700 list-disc mb-4">
          <li>
            Design, code, and maintain high-quality Android applications that
            align with user requirements and industry best practices.
          </li>
          <li>
            Write clean, efficient, and robust code. Conduct thorough testing to
            identify and resolve bugs, ensuring app stability.
          </li>
          <li>
            Collaborate closely with UI/UX designers to craft visually appealing
            and user-friendly interfaces.
          </li>
          <li>
            Seamlessly integrate APIs and third-party libraries to enhance app
            functionality.
          </li>
          <li>
            Identify and rectify performance bottlenecks, ensuring smooth and
            responsive app performance.
          </li>
          <li>
            Work experience in design patterns mobile architecture using
            frameworks such as MVVM/MVC/MVP.
          </li>
          <li>
            Implement stringent security measures to protect user data and
            ensure data privacy.
          </li>
          <li>
            Work collaboratively with cross-functional teams, including
            designers, product managers, and QA engineers.
          </li>
          <li>
            Design, develop, and integrate APIs using Retrofit for seamless
            communication between the app and backend services.
          </li>
          <li>
            Implement and integrate various ad mediation platforms to optimize
            in-app advertising.
          </li>
          <li>
            Maintain comprehensive documentation for codebase, processes, and
            project updates.
          </li>
          <li>
            Ability to understand the requirements of business and translate
            them technically.
          </li>
        </ul>
        <h2 className="text-xl font-medium mb-4 text-center">Requirements</h2>
        <ul className="text-gray-700 list-disc mb-4">
          <li>Bachelor's degree in Computer Science or a related field.</li>
          <li>
            5 years of development experience in Android App Development or
            Mobile App Developer.
          </li>
          <li>
            Expertise in Java or Kotlin and strong familiarity with Android SDK
            and Android Studio.
          </li>
          <li>
            Deep knowledge of RESTful APIs, web services integration, and
            third-party library integration.
          </li>
          <li>
            Strong problem-solving skills and ability to debug complex issues.
          </li>
          <li>
            Understanding of Google’s Android design principles and interface
            guidelines.
          </li>
          <li>Familiar with outside data sources and APIs.</li>
          <li>
            Strong knowledge of Google’s Android design principles and
            guidelines.
          </li>
          <li>Working knowledge of emerging mobile technologies.</li>
          <li>Excellent interpersonal skills.</li>
          <li>Ability to work independently.</li>
          <li>Good time management skills.</li>
        </ul>
        <h2 className="text-xl font-medium mb-4 text-center">Interested?</h2>
        <p className="text-gray-700">
          Send your resume and motivation letter to{" "}
          <a
            href="mailto:info@itsolutionshub2010.com"
            className="text-blue-500"
          >
            info@itsolutionshub2010.com
          </a>
        </p>
      </div>
    </div>
  );
}

export default AndroidDeveloper;
