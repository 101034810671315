import React from "react";
import { Link } from "react-router-dom"; // Assuming you're using react-router-dom
import LogoBVDesktop from "../../assets/logoBV green 2.png";
import LogoBVMobile from "../../assets/logoBV green 2.png";

function Webdev() {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full">
        <div className="flex justify-center items-center">
          <Link to="/">
            <img src={LogoBVMobile} alt="Mobile Logo" className="md:hidden" />
          </Link>
          <Link to="/">
            <img
              src={LogoBVDesktop}
              alt="Desktop Logo"
              className="hidden md:block"
            />
          </Link>
        </div>
      </div>
      <div className="text-left mt-8 max-w-3xl mx-auto">
        {/* Centered content */}
        <h1 className="text-3xl font-semibold mb-4 text-center">
          Travel Agent
        </h1>
        <h2 className="text-xl font-medium mb-4 text-center">About Us</h2>
        <p className="text-gray-700 mb-4">
          At IT Solutions Hub, we pride ourselves on being a trusted partner for
          businesses seeking strategic guidance and innovative solutions. With a
          wealth of experience across various industries, our team of seasoned
          consultants brings unparalleled expertise and a client-centric
          approach to every project. We are dedicated to delivering tailored
          strategies that drive operational excellence and sustainable growth.
          By joining our team, you will become part of a dynamic organization
          that values professional development, fosters a collaborative
          environment, and is committed to making a meaningful impact on our
          clients' success.
        </p>
        <h2 className="text-xl font-medium mb-4 text-center">
          Job Requirements
        </h2>
        <ul className="text-gray-700 list-disc mb-4">
          <li>
            Previous experience as a Travel Consultant / Travel Agent is
            essential.
          </li>
          <li>Excellent verbal and written communication skills in English.</li>
          <li>Strong customer service and problem-solving abilities.</li>
          <li>Influencing and negotiation skills to meet sales targets.</li>
          <li>Knowledge of global destinations and travel regulations.</li>
          <li>Minimum 3 years experience in this field.</li>
          <li>Must have knowledge of Sabre, Amadeus, and GDS.</li>
        </ul>
        <h2 className="text-xl font-medium mb-4 text-center">
          Job Descriptions
        </h2>
        <ul className="text-gray-700 list-disc mb-4">
          <li>
            Prepare detailed and customized itineraries based on client
            preferences and needs.
          </li>
          <li>
            Use CRM (Customer Relationship Management) tools to maintain client
            profiles and preferences for personalized service.
          </li>
          <li>
            Handle booking changes, cancellations, and refunds with accuracy and
            efficiency, ensuring a high level of customer satisfaction.
          </li>
          <li>Manage budgets effectively to meet profit or sales targets.</li>
          <li>
            Analyze travel options and apply competitive pricing strategies to
            find cost-effective solutions while maximizing company profits.
          </li>
          <li>
            Offer holiday packages and influence clients to choose suitable
            options.
          </li>
          <li>Guide clients about our air travel plans.</li>
          <li>Book tickets according to the customer travel plan.</li>
          <li>Build strong relationships with customers.</li>
          <li>
            Continuously update knowledge about visa and passport requirements.
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Webdev;
