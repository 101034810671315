import React from "react";
import { Link } from "react-router-dom"; // Assuming you're using react-router-dom
import LogoBVDesktop from "../../assets/logoBV green 2.png";
import LogoBVMobile from "../../assets/logoBV green 2.png";
// Assuming LogoBVMobile and LogoBVDesktop are imported somewhere else

function BackendDeveloper() {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full">
        <div className="flex justify-center items-center">
          <Link to="/">
            <img src={LogoBVMobile} alt="Mobile Logo" className="md:hidden" />
          </Link>
          <Link to="/">
            <img
              src={LogoBVDesktop}
              alt="Desktop Logo"
              className="hidden md:block"
            />
          </Link>
        </div>
      </div>
      <div className="text-left mt-8 max-w-3xl mx-auto">
        {/* Centered content */}
        <h1 className="text-3xl font-semibold mb-4 text-center">
          Backend Developer
        </h1>
        <h2 className="text-xl font-medium mb-4 text-center">
          Job Type: Full Time (Onsite)
        </h2>
        <h2 className="text-xl font-medium mb-4 text-center">About Us</h2>
        <p className="text-gray-700 mb-4">
          At IT Solutions Hub, we pride ourselves on being a trusted partner for
          businesses seeking strategic guidance and innovative solutions. With a
          wealth of experience across various industries, our team of seasoned
          consultants brings unparalleled expertise and a client-centric
          approach to every project. We are dedicated to delivering tailored
          strategies that drive operational excellence and sustainable growth.
          By joining our team, you will become part of a dynamic organization
          that values professional development, fosters a collaborative
          environment, and is committed to making a meaningful impact on our
          clients' success.
        </p>
        <h2 className="text-xl font-medium mb-4 text-center">
          Responsibilities
        </h2>
        <ul className="text-gray-700 list-disc mb-4">
          <li>
            Develop and maintain server-side logic, including APIs, database
            management, and application logic.
          </li>
          <li>Troubleshoot and debug server-side issues.</li>
          <li>
            Collaborate with front-end developers to integrate user-facing
            elements with server-side logic.
          </li>
          <li>
            Design, develop, implement, and maintain robust and scalable APIs
            using C#, JavaScript, and Python.
          </li>
          <li>Optimize application performance, scalability, and security.</li>
          <li>
            Utilize Azure APIs and Azure technologies to build and integrate
            APIs seamlessly.
          </li>
          <li>
            Collaborate with product managers, engineers, and designers to
            define API requirements and functionality.
          </li>
          <li>
            Implement strong authentication and authorization mechanisms to
            ensure API security.
          </li>
          <li>
            Write clear and concise API documentation for both internal and
            external developers.
          </li>
          <li>
            Monitor API performance, troubleshoot issues, and identify areas for
            improvement.
          </li>
          <li>
            Stay up-to-date on the latest API development trends and
            technologies.
          </li>
          <li>
            Participate in code reviews and contribute to the overall quality of
            the codebase.
          </li>
        </ul>
        <h2 className="text-xl font-medium mb-4 text-center">Qualifications</h2>
        <ul className="text-gray-700 list-disc mb-4">
          <li>Bachelor’s Degree (B.Sc. Engineering).</li>
          <li>
            4-5 years of experience as a backend developer with a focus on API
            development.
          </li>
          <li>
            Proficiency in backend languages such as Node.js, Python, Ruby, or
            PHP.
          </li>
          <li>Knowledge of integration of third-party APIs.</li>
          <li>Experience with databases (MySQL, PostgreSQL, MongoDB).</li>
          <li>
            Experience designing, developing, and maintaining APIs using C#,
            JavaScript.
          </li>
          <li>
            Solid understanding of RESTful API principles and best practices.
          </li>
          <li>
            Experience with Azure APIs and Azure technologies is a strong plus.
          </li>
          <li>
            Familiarity with server-side frameworks (e.g., Express.js, Django,
            Laravel).
          </li>
        </ul>
        <h2 className="text-xl font-medium mb-4 text-center">Interested?</h2>
        <p className="text-gray-700">
          Send your resume and motivation letter to{" "}
          <a
            href="mailto:info@itsolutionshub2010.com"
            className="text-blue-500"
          >
            info@itsolutionshub2010.com
          </a>
        </p>
      </div>
    </div>
  );
}

export default BackendDeveloper;
