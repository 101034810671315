import React from "react";
import { Link } from "react-router-dom"; // Assuming you're using react-router-dom
import LogoBVDesktop from "../../assets/logoBV green 2.png";
import LogoBVMobile from "../../assets/logoBV green 2.png";
// Assuming LogoBVMobile and LogoBVDesktop are imported somewhere else

function SEO() {
  return (
    <div className="min-h-screen flex flex-col items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="w-full">
        <div className="flex justify-center items-center">
          <Link to="/">
            <img src={LogoBVMobile} alt="Mobile Logo" className="md:hidden" />
          </Link>
          <Link to="/">
            <img
              src={LogoBVDesktop}
              alt="Desktop Logo"
              className="hidden md:block"
            />
          </Link>
        </div>
      </div>
      <div className="text-left mt-8 max-w-3xl mx-auto">
        {/* Centered content */}
        <h1 className="text-3xl font-semibold mb-4 text-center">
          SEO Specialist
        </h1>
        <h2 className="text-xl font-medium mb-4 text-center">About Us</h2>
        <p className="text-gray-700 mb-4">
          At IT Solutions Hub, we pride ourselves on being a trusted partner for
          businesses seeking strategic guidance and innovative solutions. With a
          wealth of experience across various industries, our team of seasoned
          consultants brings unparalleled expertise and a client-centric
          approach to every project. We are dedicated to delivering tailored
          strategies that drive operational excellence and sustainable growth.
          By joining our team, you will become part of a dynamic organization
          that values professional development, fosters a collaborative
          environment, and is committed to making a meaningful impact on our
          clients' success.
        </p>
        <h2 className="text-xl font-medium mb-4 text-center">The Role</h2>
        <p className="text-gray-700 mb-4">
          As an SEO Specialist, you will help improve the website’s visibility
          in search engines and increase organic traffic. The ideal candidate
          will be responsible for improving our organic search visibility
          through comprehensive SEO strategies, detailed competitor analysis,
          and keyword research, with a strong focus on both local and global SEO
          optimization. You will be working with cross-functional teams to
          enhance our website’s ranking, track performance, and provide
          actionable insights to ensure continuous improvement.
        </p>
        <h2 className="text-xl font-medium mb-4 text-center">
          Key Responsibilities
        </h2>
        <ul className="text-gray-700 list-disc mb-4">
          <li>
            Generate and present SEO performance reports, offering data-driven
            recommendations for improvement and regular updates to stakeholders.
          </li>
          <li>
            Conduct in-depth analysis of competitor websites, SEO strategies,
            backlink profiles, and keyword rankings.
          </li>
          <li>
            Identify gaps in our SEO strategy by comparing competitor
            performance and trends.
          </li>
          <li>
            Create actionable plans based on competitor insights to improve
            rankings and visibility in key markets.
          </li>
          <li>
            Develop and implement comprehensive SEO strategies for international
            markets, including optimizing for various regions and languages.
          </li>
          <li>
            Manage reflag implementation, content localization, and
            geo-targeting to enhance the global visibility of the website.
          </li>
          <li>
            Perform thorough keyword research using tools like Ahrefs, SEMrush,
            or Moz to identify high-value, relevant keywords for global and
            local markets.
          </li>
          <li>
            Analyze keyword search volumes, trends, competition levels, and
            relevance to improve content and on-page SEO strategies.
          </li>
          <li>
            Track keyword performance and ranking changes, making necessary
            adjustments to maintain or improve search visibility.
          </li>
          <li>
            Work with developers and content teams to ensure websites are
            optimized for both global and local search engines, considering
            region-specific SEO factors.
          </li>
        </ul>
        <h2 className="text-xl font-medium mb-4 text-center">
          Required Qualifications
        </h2>
        <ul className="text-gray-700 list-disc mb-4">
          <li>
            Proven experience in managing SEO campaigns for local and
            international markets, with insight into best practices for SEO and
            algorithm updates.
          </li>
          <li>
            Strong analytical skills with experience in benchmarking
            competitors, analyzing keyword gaps, and developing content
            strategies.
          </li>
          <li>
            Familiarity with technical SEO aspects such as site architecture,
            mobile optimization, page speed, indexing, and crawlability.
          </li>
          <li>
            Proficiency with tools such as Google Analytics, Google Search
            Console, SEMrush, Ahrefs, Moz, and similar reporting platforms to
            analyze and report on SEO performance.
          </li>
          <li>
            Expertise in using tools like Google Keyword Planner, Ahrefs,
            SEMrush, and other research platforms to identify and analyze target
            keywords.
          </li>
          <li>
            Strong skills in optimizing website content, meta tags, alt tags,
            internal links, and other on-page SEO factors.
          </li>
          <li>
            5 years of experience in SEO, preferably in a global context, with
            proven results in organic traffic growth and visibility in search
            results and also knowledge of ERP.
          </li>
          <li>
            Ability to present SEO reports and insights to both technical and
            non-technical audiences, with clear recommendations.
          </li>
        </ul>
        <h2 className="text-xl font-medium mb-4 text-center">
          Preferred Qualifications
        </h2>
        <ul className="text-gray-700 list-disc mb-4">
          <li>
            Google Analytics Certification, HubSpot SEO Certification, or other
            relevant SEO certifications.
          </li>
          <li>
            Familiarity with content management systems (CMS) such as WordPress,
            Magento, or Shopify.
          </li>
          <li>
            Basic understanding of HTML, CSS, and JavaScript to troubleshoot and
            improve SEO performance.
          </li>
          <li>
            Experience with implementing SEO strategies for multilingual
            websites and managing international SEO campaigns.
          </li>
        </ul>
      </div>
    </div>
  );
}

export default SEO;
