import React from "react";
import AppDev from "../components/job_pages/AppDev";
import ApplyButton from "../components/job_pages/ApplyButton";
import { Helmet } from "react-helmet";

function SEOContentWriter() {
  return (
    <div>
      <Helmet>
        <title>Android Developer</title>
      </Helmet>
      <section>
        <AppDev />
      </section>
      <section>
        <ApplyButton />
      </section>
    </div>
  );
}

export default SEOContentWriter;
