import React from "react";
import Techbuyer from "../components/job_pages/Techbuyer";
import ApplyButton from "../components/job_pages/ApplyButton";
import { Helmet } from "react-helmet";

function WebDeveloper() {
  return (
    <div>
      <Helmet>
        <title>Travel Agent</title>
      </Helmet>
      <section>
        <Techbuyer />
      </section>
      <section>
        <ApplyButton />
      </section>
    </div>
  );
}

export default WebDeveloper;
